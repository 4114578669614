//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Home',
  data () {
    return {
      tableData: [{
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第二集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第一集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第三集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第四集'
      },{
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第二集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第一集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第三集'
      }, {
        date: '2016.05.02 02:00:01',
        name: '九州缥缈录',
        number: '第四集'
      }]
    }
  }
}
